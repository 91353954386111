/* Styles for .about */
.about {
  padding-top: 100px;
  background-image: url(../../../image/fernanda.png);
  background-size: cover;
  width: 350px;
  height: 750px;
}
/* Styles for .container */
.container {
  display: flex;
    justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;

}
/* Styles for .text */
.text {
  padding: 10px;
  font-size: 10px;
  text-align: center;
  width: 50vw;
  color: var(--cor01);
  font-size: x-large;
  text-shadow: none;
  font-weight: 400; /* Normal */
  font-family: 'Dutismo', sans-serif;
  margin: auto;
}
.title {
  font-size: 30px;
  
  text-align: center;
  color: var(--cor01);
  font-family: 'Dutismo', sans-serif;
  
}
.containerMain{
  margin: auto 100px;
  padding: 20px;
  background-image: linear-gradient(160deg,rgb(83, 45, 30),var(--cor03),var(--cor03),rgb(83, 45, 24));
}

/* Media query for .text */
@media (max-width: 1300px) {
  .text {
    width: 90%;
  }
  .containerMain{
    margin: 0;
  }
  .about{
    width:200px;
    height:340px;
  }
}

