.title {
    text-align: center;
    color: var(--cor01);
    font-family: 'Dutismo', sans-serif;
    text-align: center;
    padding-top: 40px;
    width: 55vw;
    margin: auto;
}
a{
    text-decoration: none;
    color: var(--cor01);
}

.text {
    margin: 0;
    width: 100%;
    max-width: 400px;
    color: var(--cor01);
    font-size: x-large;
    text-shadow: none;
    padding: 20px;
    
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    height: auto;


}
.bgMain{
    background-image: linear-gradient(160deg,rgb(83, 45, 30),var(--cor03),var(--cor03),rgb(83, 45, 24));
    margin: auto;
    width: 55vw;
}

.insta, .whats {
    width: 30px;
}

.socialmidias {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 400px;
}

.containerInsta, .containerWhats {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;  
    width:50%;  
}

.externalLinks {
    display: flex;
    flex-direction: row;
    font-size: 10px;
    color: var(--cor01);
    width:100%;
}

.form {
    font-family: 'Dutismo';
    color: var(--cor01);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 50px;
    width: 100%;
    max-width: 400px;
}

label {
    margin-bottom: 8px;
    font-weight: bold;
}

input, .description {
    width: 100%;
    margin-bottom: 16px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: sans-serif;
}

.description {
    height: 100px;
}

.buttonForm {
    margin: auto;
    background-color: var(--cor01);
    color: var(--cor03);
    border: 2px solid var(--cor03);
    border-radius: 25px;
    padding: 10px 20px;
    width: 100px;
    font-family: 'Lora', serif;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.buttonForm:hover {
    background-color: var(--cor03);
    color: var(--cor01);
    border: 2px solid var(--cor01);
}

.error {
    color: red;
    font-size: 12px;
    margin-bottom: 8px;
}
@media (max-width: 1300px) {
    .bgMain{
        width: 97vw;

    }
    
  }

  @media(max-width:400px){
    .externalLinks{
        flex-direction: column;
        align-items: center;
        margin: auto;
    }
  }