
.contain{
  height: 95vh;
  width: 70vw;
  margin: auto;
  padding: 20px;
  text-align: center;
}

.titleJob{
  color: var(--cor03);
  font-family: Dutismo;
  text-align: center;
  padding: 50px;
  font-size: 2.5rem;
}
.textJob{
  text-align: center;
  font-family: 'Playfair Display';
  color: var(--cor03);
  font-weight: 500;
  font-size: 1.5rem;
  padding: 20px;
}
.welcomeHome {
  background-image: url(../../../image/bg_home.jpg);
  background-size: cover; 
  background-position: center; 
  background-color: #783d1933;
  background-blend-mode: overlay;
  background-attachment: fixed;
  margin: auto;
  text-align: center;
  min-height: 95vh; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logoHome{

  font-family: 'Dutismo', sans-serif;   
  letter-spacing: 3px;
  font-size: 8rem;
  color: var(--cor01);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.397);
}
.tittleHome{
  font-family: 'Playfair Display', serif; 
  font-weight: 700;
  color: var(--cor01);
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.212);
  font-size: 3.1rem;
}
.buttonHome {
  background-color: var(--cor01);
  color: var(--cor03);
  border: 2px solid var(--cor03);
  border-radius: 25px;
  padding: 10px 20px;
  width: 400px;
  font-family: 'Lora', serif;
  font-size: 30px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.buttonHome:hover {
  background-color: var(--cor03);
  color: var(--cor01);
  border: 2px solid var(--cor01);
}

.bg-1,
.bg-2,
.bg-3,
.bg-4 {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-size: cover;
  background-position: top center;
  border: solid 10px var(--cor01);
  
}

.bg-1 {
  background-image: url('../../../image/bg_1_g.jpg');
  
  
}

.bg-2 {
  background-image: url('../../../image/bg_2_g.jpg');
 
}

.bg-3 {
  background-image: url('../../../image/bg_3_g.jpg');
}

.bg-4 {
  background-image: url('../../../image/bg_4_g.jpg');
}

.containerJob{
  height: 100%;
}


.hidden {
  opacity: 0;
  visibility: hidden;
}

.fade-in {
  opacity: 1;
  visibility: visible;
  transition: opacity 1s ease-in-out;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px; 
  right: 20px; 
  background-color: var(--cor01); 
  color: var(--cor03);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  text-decoration: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
}

.scroll-to-top:hover {
  background-color: var(--cor03);
  color: var(--cor01);
}

.arrow-up {
  display: block;
}


@media (max-width: 1150px) {
  .bg-1,
  .bg-2,
  .bg-3,
  .bg-4 {

    background-size: cover;
    background-position: top center;
    height: 40vh;
    margin: 20px;
    
  }
  .contain{
    padding: 0px;
    width: 95vw;
  }

  .bg-1 {
    background-image: url('../../../image/bg_1_p.jpg');
  }

  .bg-2 {
    background-image: url('../../../image/bg_2_p.jpg');
  }

  .bg-3 {
    background-image: url('../../../image/bg_3_p.jpg');
  }

  .bg-4 {
    background-image: url('../../../image/bg_4_p.jpg');
  }

  .tittleHome{
    font-size: 2rem;
  }
  .buttonHome{
    width: 300px;
    font-size: 20px;
  }
  link{
    margin: auto;
    text-align: center;
  }
  .logoHome{
    font-size: 9vw;
  }
  .tittleHome{
    font-size: 4vw;
  }
 
}

@media(max-width:888px){
  .welcomeHome{
    background-image: url(../../../image/bg_home_p.jpg);
    background-size: cover;
    background-position: center;
  }
}
@media(max-width:375px){
  .welcomeHome{
    background-attachment: scroll;
  }
}



::-webkit-scrollbar {
  width: 12px; 
}

::-webkit-scrollbar-track {
  background: var(--cor01);
}
::-webkit-scrollbar-thumb {
  background-color: var(--cor03);  
  border-radius: 10px;    
  border: 3px solid var(--cor01); 
}

