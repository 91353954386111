header {
    top: 0;
    background-color: var(--cor01);
    color: var(--cor03);
    padding: 10px;
    display: flex;
    text-align: center;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;  
    position: relative;
}


header h1 {
    font-family: 'Dutismo', sans-serif;   
    letter-spacing: 3px ;
    font-size: xx-large;
}

.menu-icon {
    display: none;
    cursor: pointer;
}

.nav-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.nav-menu ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.nav-menu ul li {
    text-decoration: none;
    padding-right: 10px;
}

.nav-link {
    position: relative;
    color: inherit; 
    text-decoration: none;
    padding: 5px;
    transition: color 1.8s ease;
}

.nav-link::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    margin-top: 5px;
    right: 0;
    background: var(--cor03);
    transition: width 0.3s ease, right 0.3s ease;
}

.nav-link:hover {
    color: var(--cor03);
}

.nav-link:hover::after {
    width: 100%;
    left: 0;
}

@media (max-width: 768px) {
    .menu-icon {
        display: block;
    }
    .nav-menu {
        display: none;
        flex-direction: column;
        align-items: flex-start;
        background-color: var(--cor01);
        width: 100%;
        position: absolute;
        top: 50px;
        left: 0;
        z-index: 1000;
        transition: max-height 0.3s ease-in;
    }

    .nav-menu.open {
        display: flex;
    }

    .nav-menu ul {
        flex-direction: column;
    }

    .nav-menu ul li {
        padding: 10px 20px;
        width: 100%;
        text-align: left;
    }

    .nav-menu ul li a {
        width: 100%;
    }
    header h1 {

        font-size: larger;
    }
}
