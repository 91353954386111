:root{
  --cor01: #D9BCA3;
  --cor02:#c6c499;
  --cor03:#783D19;
  --cor04:#783d19b0;
  --cor05:#94946e;
  --cor06:#5F6F52;
  --cor07:#59573E;

}
@font-face {
  font-family: Dutismo;
  src: url('fonts/Dustismo/dustismo.ttf');
}
*{
  margin: 0;
  padding :0;
}



h1 , label{
  font-family: 'Playfair Display', serif;
  font-weight: 600; /* Semibold */

}

h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500; /* Medium */

}

p {
  font-family: 'Quicksand', sans-serif;
  font-weight: 400; /* Normal */


}

li {
  font-family: 'Roboto Slab', serif;
  font-weight: 600; /* Semibold */
}

footer {
  font-family: 'Lora', serif;
  font-weight: 400; /* Normal */

}
*{
  padding: 0;
  margin: 0;
}
html,body,#root{
  background-color:var(--cor01);
  height: 100%;
}
#root{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

