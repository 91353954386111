/* src/components/Pages/photos/photos.module.css */

/* Container principal */
.container {
    padding: 10px;
}

/* Estilo da grade Masonry */
.myMasonryGrid {
    display: flex;
    margin-left: -10px; /* Remover espaço entre colunas */
    width: auto;
    overflow: hidden;
}

/* Cada coluna na grade */
.myMasonryGridColumn {
    padding-left: 10px; /* Adicionar espaço entre colunas */
    background-clip: padding-box;
}

/* Cada item na coluna */
.fotos {
    margin-bottom: 10px;
    transition: transform 0.2s;
    text-align: center;
    cursor: pointer; /* Mostrar que é clicável */
}

/* Estilo das imagens na grade */
.fotos img {
    width: 100%;
    display: block;
    border-radius: 8px;
    transition: transform 1.15s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Efeito ao passar o mouse */
.fotos img:hover {
    transform: scale(1.15);
    
}

/* Estilo do conteúdo da modal */
.modalContent {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

/* Estilo da imagem no modal */
.modalImage {
    width: auto;
    max-width: 100%;
    max-height: 90vh; /* Garante que a imagem não ultrapasse 90% da altura da janela */
    object-fit: contain;  
}

/* Estilo dos botões de navegação */
.navButton {
    background: rgba(255, 255, 255, 0.5);
    border: none;
    color: rgb(0, 0, 0);
    font-size: 2rem;
    cursor: pointer;
    padding: 30px;
    border-radius: 50%;
    z-index: 10;
    transition: background 0.3s;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.navButton:hover {
    background: rgb(255, 255, 255);
    color: black;
}

.navButton:focus {
    outline: none;
}

.navButton:first-of-type {
    left: 10px;
}

.navButton:last-of-type {
    right: 10px;
}

.customModal {
    max-width: 90%; /* Define a largura máxima do modal */
    width: 100vw; /* Permite que o modal se ajuste ao conteúdo até o max-width */
    padding: 0;
    background-color: rgba(255, 255, 255, 0);
    box-shadow: none;
}

.customModal .react-responsive-modal-modal {
    max-width: 90%; /* Define a largura máxima do modal */
    width: auto; /* Permite que o modal se ajuste ao conteúdo até o max-width */
    
}

