/* src/components/scrollToTopButton.css */
.scroll-to-top {
    position: fixed;
    bottom: 30px; 
    right: 30px; 
    background-color: var(--cor03); 
    color: var(--cor01); 
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    text-decoration: none;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    opacity: 0;
    visibility: hidden;
    border: solid var(--cor04);
    cursor: pointer; 
    margin: 10px;
}
.scroll-to-top.visible {
    opacity: 1;
    visibility: visible;
}

.scroll-to-top:hover {
    background-color: var(--cor01);
    color: var(--cor03);
    
}

.arrow-up {
    display: block;
}
